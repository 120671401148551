





















































import Vue from "vue";
import { Tabs, Tab, List, Empty, Cell, CellGroup, PullRefresh, Collapse, CollapseItem, Button, Sticky, Search, Toast, Dialog } from "vant";
import Moment from "moment";
Moment.locale("zh-cn");

export default Vue.extend({
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [Empty.name]: Empty,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
    [Sticky.name]: Sticky,
    [Search.name]: Search
  },
  data() {
    return {
      page: 0,
      size: 10,
      list: [],
      type: this.$route.params.type || "",
      isActive: 1,
      tabName: "生效中",
      keyword: "",
      search: "",
      showOrder: localStorage.userId > 0 && localStorage.userId != 9351,
      refreshing: false,
      loading: false,
      loadFinished: false,
      empty: false,
      actionNames: []
    };
  },
  watch: {
    type: "onRefresh"
  },
  activated() {
    this.type = this.$route.params.type;
  },
  methods: {
    onSearch() {
      this.keyword = this.search;
      this.onRefresh();
    },
    onRefresh() {
      this.refreshing = true;
      this.page = 0;
      this.list = [];
      this.getList().finally(() => {
        this.refreshing = false;
      });
    },
    onTabChange(name: string) {
      this.tabName = name;
      if (name == "active") {
        this.isActive = 1;
      } else if (name == "inActive") {
        this.isActive = 0;
      }
      this.onRefresh();
    },
    formatTime(timestamp: number) {
      return Moment(timestamp).format("lll");
    },
    getList() {
      let url = "/api/manage/servicePlan/";
      switch (this.type) {
        case "user":
          url += "getUserPlanList";
          break;
        case "business":
          url += "getBusinessPlanList";
          break;
        case "platform":
          url += "getPlatformPlanList";
          break;
      }
      const data = {
        isActive: this.isActive,
        keyword: this.keyword,
        page: this.page++,
        size: this.size
      };
      this.loading = true;
      return this.$axios
        .post(url, data)
        .then(res => {
          const newList = res.data.data.list;
          for (const item of newList) {
            item.actionNames = [];
          }
          this.list = this.list.concat(newList);
          return Promise.resolve([res.data.data, data]);
        })
        .then(([data, requestData]) => {
          this.loadFinished = data.list.length < requestData.size;
          this.empty = data.list.length == 0 && requestData.page == 0;
        })
        .catch(() => {
          this.loadFinished = true;
          this.empty = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closePlan(id: number) {
      Dialog.confirm({
        message: "确定要关闭吗?"
      }).then(() => {
        this.$axios.post("/api/manage/servicePlan/closePlan", { servicePlanId: id }).then(() => {
          Toast.success("操作成功");
          this.onRefresh();
        });
      });
    },
    payPlan(id: number) {
      Dialog.confirm({
        message: "您确定要支付吗?"
      }).then(() => {
        this.$axios.post("/api/manage/servicePlan/payPlan", { servicePlanId: id, count: 1 }).then(() => {
          Toast.success("支付成功");
          this.onRefresh();
        });
      });
    }
  }
});
